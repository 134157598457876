.invester-opp-main {
    background: var(--white);
    height: 100vh;
    overflow-x: hidden;
}

.container-invester {
    display: block;
    margin: 115px auto 80px auto;
    overflow-x: hidden;
    width: 80%;
    min-height: 90vh;
}

.investor-opp-card {
    border: 1px solid #dedede;
    border-radius: var(--card-border-radius);
    max-height: 365px;
    position: relative;
}

.invest-button-sec {
    padding-top: 1.5em;
    text-align: center;
    position: absolute;
    right: 0;
}

.opportunity-card-content {
    padding: 24px 40px 54px 40px!important;
}
.opportunity-card-content {
    display: block !important;
}
.invest-button-sec .ant-btn {
    background: #1582D4 0% 0% no-repeat padding-box;
    border: 1px solid #1582D4;
    border-radius: 0px;
    color: #fff;
    font-family: NotoRegular !important; 
   font-weight: 500 !important;
    height: 32px;
    padding-left: var(--padding-one-em);
    padding-right: var(--padding-one-em);
    width: 100px;
    line-height: 16px;
}

.invest-button-sec .ant-btn:hover,
.invest-button-sec .ant-btn:focus {
    background: #1582D4 0% 0% no-repeat padding-box;
    border: 1px solid #1582D4;
    border-radius: 0px;
    color: #fff;
    font-family: NotoRegular !important; 
   font-weight: 500 !important;
    height: 32px;
    padding-left: var(--padding-one-em);
    padding-right: var(--padding-one-em);
    width: 100px;
    line-height: 16px;
}

.investor-opp-card .ant-card-meta-avatar {
  padding: 0px !important;
  width: 100%;
}

.investor-row .ant-col {
    margin-bottom: 2.5em;
     padding: 0px var(--padding-four-em ) 1.5em .3em !important; 
}

.investor-row {
    margin-left: 0px !important;
}

.investor-opp-card .ant-card-meta img {
    /* height: 120px;
    object-fit: contain; 
    width: 100%; */
    width: 60%;
    display: block;
    margin: auto;
    object-fit: cover;
}


.investor-opp-card .ant-card-meta-description {
    line-height: 1;
}

.investor-opp-card .primary-button {
    background: var(--primary);
    display: block;
    height: var(--fourty);
    width: 100%;
}

.investor-opp-card .ant-card-meta-title {
    margin-bottom: 0px;
}

.price-section,
.security-section {
    /* align-items: center;
    display: flex;
    justify-content: space-between; */
    /* margin-top: var(--padding-one-em); */
}

.price-section label,
.total-price {
    color: #565656;
    font-weight: 500;
    font-size: 13px;
    font-family: 'NotoRegular';
}

.price-caliculations {
    color: var(--primary);
    /* font-family: PopinRegular; */
    /* font-size: .7em; */
    width: 100%;
}

.price-buy {
    bottom: var(--padding-two-em);
    left: 5%;
    position: absolute;
    text-align: center;
    width: 90%;
}

.security-section label {
    color: #565656;
    font-weight: 500;
    font-size: 13px;
    font-family: 'NotoRegular';
}

.range-slider-div {
    align-items: center;
    display: flex;
    margin-top: var(--padding-one-em);
}

.range-slider-div label {
    color: var(--primary);
    /* font-family: PopinRegular; */
}

.range-slider-div .ant-slider {
    margin-left: var(--padding-ten);
    width: 100%;
}

.range-slider-div .ant-slider-track {
    background-color: var(--primary);
}

.range-slider-div .ant-slider-handle {
    border: 2px solid var(--primary);
}

.opportunity-h {
    margin: 2em auto auto !important;
}
.buy-ani-section {
    background: var(--primary);
    bottom: -28px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--white);
    display: none;
    /* font-family: PopinRegular; */
    height: 50px;
    line-height: 50px;
    margin-left: -6%;
    position: absolute;
    padding: 0px;
    width: 112%;
}

.company-logo-sec {
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    background-attachment: fixed;
}

.investor-opp-card .company-name {
    color: #000 !important;
   font-family: NotoRegular;
    max-width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;    
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
}

.filters-section {
    align-items: center;
    display: flex;
    margin-bottom: 3em;
}
/* 
.filters-section .ant-input-affix-wrapper > input.ant-input {
    height: 40px !important;
   
} */

.filters-section .ant-select {
    margin-left: 1em;
    height: 45px !important;
    background: #fff !important;
    border: 2px solid #b8b8b8 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none !important;
}
/* .filters-section .ant-input-affix-wrapper > input.ant-input, 
.filters-section .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff !important;
} */
.filters-section .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 43px !important;
}

.filters-section .ant-select-arrow {
    top: 42% !important;
    right: 20px !important;
    color: #1582D4 !important;
    font-size: 20px !important;
}
.filters-section .ant-input-affix-wrapper {
    border: 2px solid #b8b8b8;
    height: 45px !important;
}

.filters-section .ant-input-search-button {
    background: #fff !important;
    height: 45px !important;
   border-left: 0px !important;
   border: 2px solid #b8b8b8;
}
.investor-opportunity-title-h {
    margin-bottom: 3em !important;
}
.filters-section .ant-input-search-button:hover {
    border-left: 0px !important;
}
.filters-section .ant-input-search-button svg {
    color: #1582D4;
    font-size: 24px;
}
@keyframes bottom-to-top {
    0%   {transform: translateY(20px); opacity: 0;}
    70%  {transform: translateY(10px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
  }
  
.investor-opp-card:hover .buy-ani-section {
    display: block;
    animation-name: bottom-to-top;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-direction: alternate;
    cursor: pointer;
    z-index: 11;

}

.investor-opp-card:hover .price-caliculations {
    animation-name: bottom-to-top;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-direction: alternate;
    cursor: pointer;
    z-index: 11;
    position: absolute;
    left: 0;
    top: -4.4em;
}

.investor-opp-card-title {
    cursor: pointer;
    position: relative;
}

.hover-section {
    position: absolute;
    right: -8px;
    top: -13px;
    background: var(--primary);
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 18px;
    transform: rotate(-45deg) !important;
    transition: all 300ms ease-in-out 0s;
    display: none;
}

.investor-opp-card:hover .hover-section {
    display: block;
}
.hover-section:hover {
    transform: scale(1.2) rotate(-45deg) !important;
    transition: all 300ms ease-in-out 0s;
}
 

/****Responsive css for only invester opportunity***/

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .container-invester {
        width: 90%;
        margin-top: 70px !important;
    } 

    .investor-row {
        margin-right: 0px !important;
    }

    .investor-row .ant-col {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1030px) {
    .container-invester {
        width: 90%;
    }

    .investor-row {
        margin-right: 0px !important;
    }

    .investor-row .ant-col {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media only screen and (max-width: 767px) {
    .join-investor-h {
        margin-left: 0px !important;
    }
    .investor-row .ant-col {
        padding: 0px!important;
    }

    .container-invester {
        width: 90%;
        margin-top: 70px !important;
    }

    .hover-section:hover {
        transform: scale(0) rotate(-45deg) !important;
        transition: all 300ms ease-in-out 0s;
    }

    .hover-section {
        display: block !important;
        right: 1px !important;
    }
}
  
.opportunity-category {
    color: #1582D4; 
    font-family: NotoRegular;
    text-transform: capitalize;
    font-weight: 500;
}

.fully-subscribed {
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    left: -7px;
    font-family: 'MontserratMedium';
    background: var(--primary);
    padding: 3px;
    color: #fff;
}

